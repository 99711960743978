import React, { useState } from "react";

import designImage from "../../assets/images/services/design.svg";
import uiImage from "../../assets/images/services/UI.svg";
import websiteImage from "../../assets/images/services/website.svg";
import brandingImage from "../../assets/images/services/branding.svg";
import appImage from "../../assets/images/services/app.svg";
import mvpImage from "../../assets/images/services/mvp.svg";
import eyeImage from "../../assets/images/services/eye.svg";

import useMagicCards from "../magicCards";
import ServiceDetails from "./ServiceDetails";

const Services = () => {
  const magicCardsRef = useMagicCards();
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  return (
    <>
      {selectedService && (
        <ServiceDetails
          title={selectedService.title}
          description={selectedService.description}
          image={selectedService.image}
          onClose={() => setSelectedService(null)}
        />
      )}

      <div className="services-container">
        <div className="services-title-container">
          <img
            className="animated"
            src={eyeImage}
            alt="your vision, fully executed"
            loading="lazy"
          />
          <h2 className="animated">Your vision, fully executed.</h2>
          <p className="animated">
            Empowering Founders to create products that last and brands that
            captivate.
          </p>
        </div>

        <div className="services-item-container" ref={magicCardsRef}>
          <div className="service-item animated magic-card">
            <div>
              <h3>Custom Design</h3>
              <p>
                Compelling visuals that tell your story. From simple pitch decks
                to complex animations.
              </p>
  
            </div>

            <img src={designImage} alt="" />
          </div>

          <div className="service-item animated magic-card">
            <div>
              <h3>Brand Design</h3>
              <p>
                Create a distinctive brand identity that connects emotionally
                and drives lasting customer relationships.
              </p>

            </div>

            <img src={brandingImage} alt="" />
          </div>

          <div className="service-item animated magic-card">
            <div>
              <h3>UI/UX Design</h3>
              <p>
                Irresistible Interfaces that make your users wanna lick the
                screen.
              </p>

            </div>

            <img src={uiImage} alt="" />
          </div>

          <div className="service-item animated magic-card">
            <div>
              <h3>Website Development</h3>
              <p>
                Inspire trust, lead with confidence, and connect with your
                audience.
              </p>

            </div>

            <img src={websiteImage} alt="" />
          </div>

          <div className="service-item animated magic-card">
            <div>
              <h3>MVP Development</h3>
              <p>
                Transform your concept into a market-ready MVP in just two
                weeks.
              </p>
     
            </div>

            <img src={mvpImage} alt="" />
          </div>

          <div className="service-item animated magic-card">
            <div>
              <h3>App Development</h3>
              <p>
                Your dream application, coded with purpose and built to impact.
              </p>
              
            </div>

            <img src={appImage} alt="" />
          </div>
        </div>

        <div className="services-more">
          <h3>and more...</h3>
          <div className="services-more-items">
            <p>Pitch Deck</p>
            <p>CRO</p>
            <p>Landing Page</p>
            <p>Framer Development</p>
            <p>Wireframes</p>
            <p>Slide Design</p>
            <p>Illustrations</p>
            <p>Digital Ads</p>
            

          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
