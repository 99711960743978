// CaseStudy.js
import React from 'react';
import { useParams } from 'react-router-dom';
import caseStudiesData from '../case-studies/caseStudiesData';
import NavBar from '../NavBar/NavBar';
import GoBackIcon from '../GoBackIcon/GoBackIcon';

const CaseStudy = () => {
  const { id } = useParams(); // Get the case study ID from the URL

  // Create a map of case study components using the data
  const caseStudyComponents = caseStudiesData.reduce((acc, study) => {
    acc[study.id] = study.content; // Map id to content component
    return acc;
  }, {});

  const CaseStudyComponent = caseStudyComponents[id]; // Look up the component by ID

  if (!CaseStudyComponent) {
    return <div>Case study not found.</div>; // Handle case where no component is found
  }

  return (
    <>  
        <NavBar />
        <GoBackIcon />
        <CaseStudyComponent />
    </>
  ); // Render the found component
};

export default CaseStudy;
