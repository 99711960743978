import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';

const NavBar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      
      // Show navbar if scrolling up or at top of page
      setIsVisible(
        (prevScrollPos > currentScrollPos) || currentScrollPos < 10
      );
      
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = (elementId) => {
    setIsOpen(false);
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <nav className={`${isVisible ? 'nav-visible' : 'nav-hidden'}`}>
      <div className='logo-container'>
        <Link to="/"><img src={logo} alt="logo" /></Link>
      </div>

      <div className='burger-icon-container'>
        <div className={`burger-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
       
      <div className={`menu-container ${isOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <div 
              onClick={() => handleScroll('pricing')} 
              style={{ cursor: 'pointer' }}
            >
              Pricing
            </div>
          </li>

          <li>
            <div 
              onClick={() => handleScroll('contact')} 
              style={{ cursor: 'pointer' }}
            >
              Contact
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
