import React, { useState, useMemo } from "react";

// Pricing Section Component
const PricingSection = ({ packages }) => (
  <>
    {packages.map((pkg, index) => (
      <div key={index} className={`pricing animated ${pkg.option.toLowerCase()}-option`}>
        
        <span className="pricing-option">{pkg.option}</span>
        

     

        <h3 className="pricing_title">{pkg.title}</h3>
        <p className="pricing_description">{pkg.description}</p>
        <p className="pricing_price">Starts at: <b className="pricing_notPrice">{pkg.originalPrice}</b> ${pkg.price}</p>
        <hr />
        <ul>
          {/* Render grey features */}
          {pkg.greyFeatures.map((feature, idx) => (
            <li key={idx} className="fas fa-check grey-feature">
              <p>{feature}</p>
            </li>
          ))}
          {/* Render green features */}
          {pkg.greenFeatures.map((feature, idx) => (
            <li key={idx} className="fas fa-check green-feature">
              <p>{feature}</p>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </>
);

// Main Component
const Pricing = () => {
  // Define service data
  const servicesData = {

    UIUX: {
      basic: {
        option: "Basic",
        title: "Basic UI/UX",
        price: "390",
        originalPrice: "2,000",
        description:
          "Essential UI Design to launch your product fast.",
        greyFeatures: ["Up to 3 Pages"],
        greenFeatures: [
          "Responsive Design",
          "Basic User Experience Research",
        ],
      },
      standard: {
        option: "Standard",
        title: "Standard UI/UX",
        price: "590",
        originalPrice: "4,000",
        description:
          "Enhanced User Experience and user-friendly interfaces.",
        greyFeatures: ["Up to 7 Pages"],
        greenFeatures: [
          "Responsive Design",
          "Standard User Experience Research",

        ],
      },
      premium: {
        option: "Premium",
        title: "Premium UI/UX",
        price: "4,990",
        originalPrice: "8,000",
        description:
          "Complete UI Design built to scale with your business.",
        greyFeatures: [],
        greenFeatures: [
          "+10 Pages",
          "Responsive Design",
          "In-depth User Experience Research",
        ],
      },
    },

    Branding: {
      basic: {
        option: "Basic",
        title: "Basic Branding",
        price: "390",
        originalPrice: "1,000",
        description: "Basic Branding Package to ship your product fast.",
        greyFeatures: ["1 Logo Design Option", "Limited Revisions", "Brand Book"],
        greenFeatures: ["Brand Identity"],
      },
      standard: {
        option: "Standard",
        title: "Standard Branding",
        price: "590",
        originalPrice: "2,000",
        description: "Enhanced branding package with additional logo options.",
        greyFeatures: ["3 Logo Design Options", "Limited Revisions"],
        greenFeatures: ["Brand Guidelines", "Brand Identity"],
      },
      premium: {
        option: "Premium",
        title: "Premium Branding",
        price: "4,990",
        originalPrice: "10,000",
        description:
          "Complete branding package with unlimited revisions and custom graphics.",
          greyFeatures: ["Up to 5 Logo Design Options"],
          greenFeatures: ["Unlimited Revisions", "Advanced Brand Guidelines", "Brand Identity"],
      },
    },

    Website: {
      basic: {
        option: "Basic",
        title: "Basic Website",
        price: "390",
        originalPrice: "2,000",
        description:
          "Essential website with core features to establish your online presence.",
        greyFeatures: ["Up to 3 Pages"],
        greenFeatures: [
          "Responsive Design",
          "Optimized to sell",
        ],
      },
      standard: {
        option: "Standard",
        title: "Standard Website",
        price: "590",
        originalPrice: "4,000",
        description:
          "Enhanced website with custom features to engage your audience.",
        greyFeatures: ["Up to 7 Pages"],
        greenFeatures: [
          "Responsive Design",
          "Optimized to sell",
          "Custom Graphics",

        ],
      },
      premium: {
        option: "Premium",
        title: "Premium Website",
        price: "4,990",
        originalPrice: "8,000",
        description:
          "Complete website with advanced features and custom functionalities.",
        greyFeatures: [],
        greenFeatures: [
          "+10 Pages",
          "Advanced SEO Setup",
          "Custom Graphics & Animations",
          "Optimized to sell",
        ],
      },
    },

    MVP: {
      basic: {
        option: "Basic",
        title: "Basic MVP",
        price: "1,990",
        originalPrice: "5,000",
        description: "Validate your idea with essential features.",
        greyFeatures: ["1-2 Core Functionalities", "Basic Authentication", "Simple Database Setup"],
        greenFeatures: [
          "Responsive Design",
        ],
      },
      standard: {
        option: "Standard",
        title: "Standard MVP",
        price: "3,990",
        originalPrice: "8,000",
        description:
          "Additional features for a broader test market.",
        greyFeatures: ["3-5 Core Functionalities"],
        greenFeatures: [
          "Responsive Design",
          "User Authentication",
          "Database Setup",
        ],
      },
      premium: {
        option: "Premium",
        title: "Premium MVP",
        price: "10,000",
        originalPrice: "20,000",
        description:
          "All the features you need for large-scale testing.",
        greyFeatures: [],
        greenFeatures: [
          "Responsive Design",
          "User Authentication",
          "Database Setup",
          "Custom API Integration",
          "Scalable Architecture",
        ],
      },
    },

    App: {
      basic: {
        option: "Basic",
        title: "Basic App",
        price: "5,000",
        originalPrice: "8,000",
        description:
          "Core app with essential features to launch your idea.",
          greyFeatures: ["1-2 Core Functionalities", "Basic Authentication", "Simple Database Setup"],
          greenFeatures: [
            "Responsive Design",
          ],
      },
      standard: {
        option: "Standard",
        title: "Standard App",
        price: "10,000",
        originalPrice: "15,000",
        description:
          "Enhanced app with additional functionality and custom UI.",
          greyFeatures: ["3-5 Core Functionalities"],
          greenFeatures: [
            "Responsive Design",
            "User Authentication",
            "Database Setup",
          ],
      },
      premium: {
        option: "Premium",
        title: "Premium App",
        price: "29,000",
        originalPrice: "100,000",
        description:
          "Full-featured app with advanced functionality for both platforms.",
          greyFeatures: [],
          greenFeatures: [
            "Responsive Design",
            "User Authentication",
            "Database Setup",
            "Custom Integrations",
            "Scalable Architecture",
            "Multiplatform"

          ],
      },
    },
  };

  const [selectedService, setSelectedService] = useState("Branding");

  // Use memoization to get the package details for the selected service
  const pricingDetails = useMemo(() => {
    const service = servicesData[selectedService];
    if (!service) return [];
    // Return all packages (basic, standard, premium) as an array
    return Object.values(service);
  }, [selectedService, servicesData]);

  return (
    <div className="pricing-container" id="pricing">
      <div className="title-description-container">
        <h2 className="animated">Simple pricing for Startups</h2>
        <p className="animated">
          No hidden fees or surprises — just flexible plans that grow with your
          business
        </p>
      </div>

      {/* Service Selection Buttons */}
      <div className="pricing_buttons animated">
        {Object.keys(servicesData).map((service) => (
          <button
            key={service}
            onClick={() => setSelectedService(service)}
            className={service === selectedService ? "active" : ""}
          >
            {service}
          </button>
        ))}
      </div>

      {/* Pricing Section */}
      <PricingSection packages={pricingDetails} />

      <p>Want a custom price for multiple services? Share your vision →</p>
    </div>
  );
};

export default Pricing;
