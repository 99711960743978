import React from "react";
import { useRef, useState } from "react";

const Dialog = ({buttonName}) => {
  // Use a ref to access the dialog element
  const dialogRef = useRef(null);

  // Function to show the dialog
  const showDialog = () => {
    if (dialogRef.current) {
      dialogRef.current.showModal();
    }
  };

  // Function to close the dialog
  const closeDialog = () => {
    if (dialogRef.current) {
      dialogRef.current.close();
    }
  };


  return (
    <>
      <button className="dialogButton animated" onClick={showDialog}>
        {buttonName}
      </button>
      <dialog ref={dialogRef}>
        <button className="closeDialog" onClick={closeDialog}>
          x
        </button>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSd3suydHl-vYPKFX_tNtYiVNM_ts4vmCipjNg2ab162E9SNqA/viewform?embedded=true"
          width="640"
          height="850"
          frameborder="0"
          marginheight="10"
          marginwidth="0"
        >
        </iframe>
      </dialog>
    </>
  );
};

export default Dialog;
