import React, { useState, useEffect } from 'react';

const ServiceDetails = ({ title, description, image, onClose }) => {
    return (
        <div className="service-popup-overlay" onClick={onClose}>
            <div className="service-popup" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>×</button>
                <div className="service-popup-content">
                <h2>{title}</h2>
                <p>{description}</p>
                </div>
                <div className="service-popup-image">
                    <img src={image} alt={title} />
                </div>

            </div>
        </div>
    );
};

export default ServiceDetails;